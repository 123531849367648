import { IUserDataSummary, IUserSettings } from '@models';
import { createAction, props } from '@ngrx/store';

export const SummaryRequest = createAction('USER_SUMMARY_REQUEST');
export const SummarySuccess = createAction(
  'USER_SUMMARY_SUCCESS',
  props<{ summary: IUserDataSummary }>()
);
export const AgreeToTermsRequest = createAction('USER_AGREE_TO_TERMS_REQUEST');
export const AgreeToTermsSuccess = createAction('USER_AGREE_TO_TERMS_SUCCESS');

export const SettingsRequest = createAction('USER_SETTINGS_REQUEST');
export const SettingsSuccess = createAction(
  'USER_SETTINGS_SUCCESS',
  props<{ settings: IUserSettings }>()
);
export const SettingsUpdateRequest = createAction(
  'USER_SETTINGS_UPDATE_REQUEST',
  props<{ settings: Partial<IUserSettings> }>()
);
export const SettingsUpdateSuccess = createAction(
  'USER_SETTINGS_UPDATE_SUCCESS',
  props<{ settings: IUserSettings }>()
);
export const UserDeleteRequest = createAction('USER_DELETE_REQUEST');
export const UserDeleteSuccess = createAction('USER_DELETE_SUCCESS');
export const UserError = createAction('USER_ERROR');
export const LogoutRequest = createAction('USER_LOGOUT_REQUEST');
export const LogoutSuccess = createAction('USER_LOGOUT_SUCCESS');
export const LoginSuccess = createAction('USER_LOGIN_SUCCESS');

export type ActionsUnion =
  | typeof SummaryRequest
  | typeof SummarySuccess
  | typeof SettingsRequest
  | typeof SettingsSuccess
  | typeof SettingsUpdateRequest
  | typeof SettingsUpdateSuccess
  | typeof UserDeleteRequest
  | typeof UserDeleteSuccess
  | typeof UserError
  | typeof LogoutRequest
  | typeof LogoutSuccess
  | typeof LoginSuccess;
